<template>
  <TepmplateBlock
    content-class="mt-4 d-block overflow-auto custom-scrollbar"
    mod-class
    title-page="Список пользователей"
  >
    <div class="user-list">
      <div class="user-list__item" v-for="item in items" :key="item.text">
        <router-link class="user-list__link" :to="item.link">
          <img
            src="../../resource/img/user-icon/edit.svg"
            alt="edit icon"
            class="user-list__edit-icon"
          />
        </router-link>

        <img :src="item.src" alt="" class="user-list__img" />

        <h3 class="user-list__title">
          {{ item.userId === currentUser.id ? "Профиль" : item.text }}
        </h3>

        <p class="user-list__email">
          {{ item.email }}
        </p>

        <p class="user-list__model">
          {{ item.userRole === 3 ? "Админ" : "Менеджер" }}
        </p>

        <b-button
          v-if="item.userId !== currentUser.id"
          :id="item.id"
          class="user-list__menu"
          variant="primary"
        >
          <img src="../../resource/img/dots-menu.svg" alt="menu" />
        </b-button>

        <b-popover
          custom-class="user-list__popover popover-notice"
          :target="item.id"
          triggers="hover focus"
          placement="left"
        >
          <div class="user-list__list-action">
            <b-button-group vertical class="user-list__btn-group">
              <b-button
                :disabled="item.userRole === 3"
                @click="onPopoverClick(item.userId, '3', item.userRole)"
                class="user-list__action-btn"
              >
                <img
                  src="../../resource/img/user-icon/admin.svg"
                  alt="admin icon"
                  class="user-list__icon"
                />
                <span class="user-list__text">Сделать администратором</span>
              </b-button>
              <b-button
                :disabled="item.userRole === 4"
                @click="onPopoverClick(item.userId, '4', item.userRole)"
                class="user-list__action-btn"
              >
                <img
                  src="../../resource/img/user-icon/manage.svg"
                  alt="manage icon"
                  class="user-list__icon"
                />
                <span class="user-list__text">Сделать менеджером</span>
              </b-button>
              <b-button
                @click="deleteUser(item.userId)"
                class="user-list__action-btn"
              >
                <img
                  src="../../resource/img/user-icon/delete.svg"
                  alt="delete icon"
                  class="user-list__icon"
                />
                <span class="user-list__text user-list__text_alert"
                  >Удалить пользователя</span
                >
              </b-button>
            </b-button-group>
          </div>
        </b-popover>
      </div>
    </div>
  </TepmplateBlock>
</template>

<script>
import TepmplateBlock from "../../components/TepmplateBlock";
import RequestManager from "../../function/request/RequestManager";
import { mapGetters } from "vuex";
export default {
  name: "UserList",
  components: { TepmplateBlock },
  computed: {
    ...mapGetters(["currentUser"]),
    offices() {
      return this.$store.getters["officeList"];
    }
  },
  created() {
    this.fetchData();
  },

  data() {
    return {
      items: []
    };
  },
  mounted() {
    this._getAcl();
  },
  methods: {
    _getAcl() {
      RequestManager()
        .getAcl("companies", "companyoffice", 30)
        .then(data => {
          console.log(data);
        });
    },
    onPopoverClick(id, value, currentRole) {
      if (currentRole !== value) {
        const data = { role: value };
        RequestManager()
          .updateUser(id, data)
          .then(data => {
            if (data.id === id) {
              const newItems = this.items.map(item => {
                if (item.userId === id) {
                  return { ...item, userRole: +value };
                }
                return item;
              });
              this.$set(this, "items", newItems);
            }
          });
      }
    },
    deleteUser(id) {
      RequestManager()
        .deleteUser(id)
        .then(data => {
          if (data.success) {
            this.fetchData();
          } else {
            const error = data.logError.response.data.detail;
            this.$bvToast.toast(error, {
              variant: "danger",
              title: "Ошибка"
            });
          }
        });
    },
    fetchData() {
      RequestManager()
        .getUsersList()
        .then(data => {
          console.log(data);
          const items = data.results.map(user => {
            return {
              text: user.first_name + " " + user.last_name,
              link: "/edit-user/" + user.id + "/",
              src: user.profile_picture
                ? user.profile_picture
                : "https://via.placeholder.com/150?Text=No+Photo",
              userRole: user.role,
              id: "popover-" + user.id,
              userId: user.id,
              email: user.email
            };
          });
          this.items = items;
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.user-list {
  display: grid;
  grid-gap: 13px;
  grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
}
.user-list__item {
  position: relative;
  padding: 19px 13px;
  //width: 180px;
  //height: 136px;
  background: var(--main-card-color);
  box-shadow: 0 4px 25px rgba(0, 0, 0, 0.01);
  border-radius: 6px;

  //&:not(:last-child) {
  //  margin-right: 13px;
  //}
}

.user-list__title {
  margin-bottom: 5px;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  letter-spacing: 0.04em;

  color: var(--card-text-color);
}

.user-list__email {
  margin-bottom: 5px;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  letter-spacing: 0.04em;

  color: var(--card-text-color);
}

.user-list__img {
  display: block;
  margin: 0 auto;
  height: 51px;
  width: 51px;
  margin-bottom: 10px;
  border-radius: 50%;
}

.user-list__model {
  margin: 0;
  margin-bottom: 17px;
  padding: 0;
  font-size: 12px;
  line-height: 14px;

  text-align: center;
  letter-spacing: 0.04em;

  color: #cfcfcf;
}

.user-list__menu {
  position: absolute;
  top: 0;
  height: 37px;
  width: auto;
  padding: 10px;
  right: 0;
}

.user-list__btn-group {
  display: flex;
  height: 100%;
}

.user-list__list-action {
  min-width: 150px;
  height: 100px;

  background: #ffffff;
  box-shadow: 0 4px 25px rgba(0, 0, 0, 0.04);
  border-radius: 6px;
}

.user-list__icon {
  display: inline-block;
  margin-right: 7px;
}

.user-list__action-btn {
  display: flex;
  align-items: center;

  width: 100%;
  height: 10px;
  padding: 5px 9px;
  background-color: transparent;
  border: 0;

  font-size: 10px;
  line-height: 9px;
  letter-spacing: 0.04em;

  color: #4b4b67;
}

.user-list__text {
  &_alert {
    color: #fb5717;
  }
}

.user-list__link {
  position: absolute;
  top: 8px;
  left: 8px;
}

.btn {
  background-color: var(--main-card-color);
}

.user-list__action-btn:disabled {
  background-color: var(--second-main-color);
}
</style>
